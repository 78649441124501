import { Card, CardContent, Grid, TextField, Typography } from "@material-ui/core";
import apis from "apis";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { OpeDialog } from "components";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },

  mt: {
    marginTop: theme.spacing(2)
  },

}));

function PolicyInput({ title, editItem, setEditItem, paramKey, placeholder = '', isDate = false }) {
  const classes = useStyles();
  return (
    <Grid item xs={4}>
      <Typography variant="h6">{title}</Typography>
      <TextField
        placeholder={placeholder}
        className={classes.dialogInput}
        size="small"
        variant="outlined"
        type={isDate ? "date" : "text"}
        value={editItem[paramKey]}
        onChange={(e) => setEditItem({
          ...editItem,
          [paramKey]: e.target.value
        })} />
    </Grid>
  )
}

function initItems() {
  return {
    agent: "",
    minPoint: 0,
    maxPoint: 0,
    pricePerPoint: "0",
  }
}


const EditorFragment = (props) => {
  const classes = useStyles();
  const { editItem, setEditItem } = props;
  return (<Card className={classes.mt}>
    <CardContent>
      <Grid
        container
        spacing={3}
        wrap="wrap"
      >
        <PolicyInput title="代理商" editItem={editItem} setEditItem={setEditItem}
          paramKey="agent" />
        <PolicyInput title="积分起" editItem={editItem} setEditItem={setEditItem}
          paramKey="minPoint" />
        <PolicyInput title="积分止" editItem={editItem} setEditItem={setEditItem}
          paramKey="maxPoint" />
        <PolicyInput title="积分价值（元）" editItem={editItem} setEditItem={setEditItem}
          paramKey="pricePerPoint" />
      </Grid>
    </CardContent>
  </Card>);
}

const PointTicketPolicyEditorDialog = (props) => {
  const { editSource, setRefresh, openDialog, setOpenDialog, isEdit } = props;
  const [editItem, setEditItem] = useState(initItems());
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!!editSource) {
      setEditItem({ ...editSource })
    }
  }, [editSource, openDialog])

  return (<OpeDialog
    title={isEdit ? "编辑" : "新增"}
    opeContent={
      <EditorFragment
        isEdit={isEdit}
        editItem={editItem}
        setEditItem={setEditItem}
      />
    }
    openDialog={openDialog}
    setOpenDialog={setOpenDialog}
    doDialog={
      () => {
        apis.addOrUpdatePointTicketPolicy({
          ...editItem
        }).then(res => {
          if (res.status === 0) {
            setRefresh(+new Date());
            enqueueSnackbar(isEdit ? '修改成功' : '添加成功', {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            });
            setOpenDialog(false);
          } else {
            enqueueSnackbar(res.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            });
          }
        })
      }
    }
    okText="保存"
    okType="primary" />
  );
}


PointTicketPolicyEditorDialog.propTypes = {
  className: PropTypes.string,
  setRefresh: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
};


export default PointTicketPolicyEditorDialog;