import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {useParams} from "react-router-dom";
import apis from "apis";
import BaseFragment from "./Base";
import TripFragment from "./Trips"
import OrderPolicyFragment from "./OrderPolicyPannel";
import PassengerFragment from "./Passenger"
import TicketPanel from "./TicketPanel";
import RefundFragment from "./RefundPanel";
import ChangeFragment from "./ChangePanel";
import FlightChangeFragment from "./FlightChangePanel";
import InsuranceFragment from "./InsurancePanel";
import LogPanel from "./LogPanel";
import PaymentRefundFragment from "./PaymentRefundPanel";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    tableButton: {
        marginRight: theme.spacing(1)
    },
    mt: {
        marginTop: theme.spacing(2)
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        fontSize: 14
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

function OrderDetail() {
    const classes = useStyles();

    const params = useParams();

    const [order, setOrder] = useState({
        orderInfo: {},
        passengerList: [],
        flightList: [],
        policyList: [],
        ticketList: [],
        refundList: [],
        paymentRefundList: [],
        changeList: [],
        orderOperateLogList: [],
        flightChangeList: [],
        insuranceList: []
    })
    const [refresh, setRefresh] = useState(+new Date());


    useEffect(() => {
        apis.orderDetail({
            orderNo: params.id
        }).then(res => {
            if (res.status === 0) {
                setOrder(res.data);
            }
        })
            .catch(e => {
                console.log(e);
            });
    }, [params.id, refresh]);
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <BaseFragment order={order} reload={() => {
                    setRefresh(+new Date())
                }}/>
                <TripFragment order={order}/>
                <OrderPolicyFragment order={order}/>
                <PassengerFragment order={order}/>
                <TicketPanel order={order} reload={() => {
                    setRefresh(+new Date())
                }}/>
                <PaymentRefundFragment order={order} reload={() => {
                    setRefresh(+new Date())
                }}/>
                <RefundFragment order={order} reload={() => {
                    setRefresh(+new Date())
                }}/>
                <ChangeFragment order={order} reload={() => {
                    setRefresh(+new Date())
                }}/>
                <FlightChangeFragment order={order} reload={() => {
                    setRefresh(+new Date())
                }}/>
                <InsuranceFragment order={order} reload={() => {
                    setRefresh(+new Date())
                }}/>
                <LogPanel order={order}/>
            </div>

        </div>
    )
}

export default OrderDetail;