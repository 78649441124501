import {Card, CardContent, CardHeader, Button, Grid, Typography, TextField} from "@material-ui/core";
import {Table} from 'views/common';
import React from "react";
import {makeStyles} from "@material-ui/styles";
import apis from 'apis';
import {useState} from "react";
import {OpeDialog} from "components";

const useStyles = makeStyles(theme => ({
    mt: {
        marginTop: theme.spacing(2)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const PaymentRefundFragment = (props) => {
    const {order, reload} = props;
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);
    const [paymentRefund, setPaymentRefund] = useState({orderNo: order.orderInfo.orderNo, amount: 0, reason: ''});
    return (
        <Card className={classes.mt}>
            <CardHeader title="退款记录" 
              action={<Button size="small" color="primary" className={classes.tableButton} onClick={() => {setPaymentRefund({orderNo: order.orderInfo.orderNo, amount: 0, reason: ''});setOpenDialog(true)}}>创建退款</Button>}
            />
            <CardContent>
                <Table columns={[
                    {
                        label: '退款单号',
                        render: (item) => {
                            return item.id;
                        }
                    },
                    {
                        label: '支付单号',
                        render: (item) => {
                            return item.outTradeNo;
                        }
                    },
                    {
                        label: "支付平台单号",
                        render: (item) => {
                            return item.tradeNo;
                        }
                    }, {
                        label: "支付平台",
                        render: (item) => {
                            return item.service;
                        }
                    }, {
                        label: "退款金额",
                        render: (item) => {
                            return item.amount*1.0/100;
                        }
                    }, {
                        label: "退款备注",
                        render: (item) => {
                            return item.note;
                        }
                    }, {
                        label: "退款状态",
                        render: (item) => {
                            return item.success ? "成功" : "失败";
                        }
                    }, {
                      label: "失败原因",
                      render: (item) => {
                          return item.reason;
                      }
                    }, {
                        label: "时间",
                        render: (item) => {
                            return item.gmtCreated;
                        }
                    }
                ]}
                       data={order.paymentRefundList || []}
                >
                </Table>
            </CardContent>
            <OpeDialog
                title="创建退款"
                opeContent={(
                  <>
                  <Grid
                    container
                    spacing={6}
                    wrap="wrap"
                  >
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="h6">金额（元）</Typography>
                      <TextField
                        className={classes.dialogInput}
                        gutterBottom
                        size="small"
                        variant="outlined"
                        value={paymentRefund.amount}
                        onChange={(e) => setPaymentRefund({
                          ...paymentRefund,
                          amount: e.target.value
                        })} />
                      <Typography gutterBottom variant="h6">备注</Typography>
                      <TextField
                        className={classes.dialogInput}
                        size="small"
                        variant="outlined"
                        value={paymentRefund.reason}
                        onChange={(e) => setPaymentRefund({
                          ...paymentRefund,
                          reason: e.target.value
                        })} />
                    </Grid>
                  </Grid>
                </>
                )}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                doDialog={() => {
                    return apis.addPaymentRefund(paymentRefund).then(res => {
                        if (res.status === 0) {
                            reload();
                            return;
                        }
                        throw new Error(res.message);
                    });
                }}
                okText="保存"
                okType="primary"/>
        </Card>
    );
}

export default PaymentRefundFragment;