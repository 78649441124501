import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import { Table } from 'views/common';
import apis from "apis";
import PropTypes from "prop-types";
import Toolbar from "./Toolbar";
import Pagination from "@material-ui/lab/Pagination";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SettingsIcon from "@material-ui/icons/Settings";
import PointTicketPolicyEditorDialog from "./PointTicketPolicyEditorDialog";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    tableButton: {
        marginRight: theme.spacing(1)
    },
    mt: {
        margin: theme.spacing(2)
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        fontSize: 14
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    dialogInput: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
}));


function PointTicketPolicy(props) {
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);
    const [openDelDialog, setOpenDelDialog] = useState(false);
    const [page, setPage] = useState(1);
    const [refresh, setRefresh] = useState(+new Date());
    const [keyword, setKeyword] = useState('');
    const [pointTicketPolicyList, setPointTicketPolicyList] = React.useState([]);

    const [editSource, setEditSource] = useState({});


    useEffect(() => {
        apis.listPointTicketPolicy({

        }).then(res => {
            if (res.status === 0) {
                setPointTicketPolicyList(res.data);
            }
        }).catch(console.log);
    }, [refresh])

    const showData = pointTicketPolicyList.filter(item => {
        return JSON.stringify(item).includes(keyword);
    });

    return (
        <Card className={classes.mt}>
            <CardHeader title="积分票价格配置" />
            <CardContent>
                <Toolbar pointTicketPolicyList={pointTicketPolicyList} setRefresh={setRefresh} onInputChange={
                    (value) => {
                        setKeyword(value);
                        setPage(1);
                    }
                } ></Toolbar>

                <div className={classes.content}>
                    <Table columns={[
                        {
                            label: 'ID',
                            key: 'id'
                        },
                        {
                            label: '代理商',
                            key: 'agent'
                        },
                        {
                            label: "积分范围",
                            render: (item) => {
                                return (
                                    <p>
                                        {item.minPoint}&nbsp;&nbsp;~&nbsp;&nbsp;{item.maxPoint}
                                    </p>
                                )
                            }
                        },
                        {
                            label: "积分价值（元）",
                            key: 'pricePerPoint'
                        },
                        {
                            label: "操作",
                            render: (item) => {
                                return (
                                    <>
                                        <Button
                                            startIcon={<SettingsIcon style={{ fontSize: 16 }} />}
                                            size="small"
                                            color="primary"
                                            className={classes.tableButton}
                                            onClick={() => {
                                                setEditSource(item);
                                                setOpenDialog(true)
                                            }}
                                            variant="contained">编辑</Button>
                                        <Button
                                            startIcon={<VisibilityIcon style={{ fontSize: 16 }} />}
                                            size="small"
                                            color="primary"
                                            className={classes.tableButton}
                                            onClick={() => {
                                              setEditSource(item);
                                              setOpenDelDialog(true)
                                            }}
                                            variant="contained">删除</Button>
                                    </>
                                )
                            }
                        }
                    ]}
                        data={showData}
                        >
                    </Table>
                </div>
            </CardContent>
            <CardActions className={classes.actions}>
                <Pagination
                    count={Math.ceil(showData.length / 10) || 1}
                    page={page}
                    onChange={(event, page) => {
                        setPage(page);
                    }}
                    shape="rounded" />
            </CardActions>
            <PointTicketPolicyEditorDialog
                pointTicketPolicyList={pointTicketPolicyList}
                isEdit={true}
                editSource={editSource}
                setRefresh={setRefresh}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
            />
            <Dialog
              open={openDelDialog}
              onClose={() => setOpenDelDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"确定删除?"}</DialogTitle>
              <DialogActions>
                <Button onClick={() => setOpenDelDialog(false)} color="primary">
                  取消
                </Button>
                <Button onClick={
                  () => {
                    setOpenDelDialog(false)
                    apis.deletePointTicketPolicy({
                        id: editSource.id
                    }).then(res => {
                        if (res.status === 0) {
                            setRefresh(+new Date())
                        }
                    }).catch(console.log);
                  }
                } color="primary" autoFocus>
                  确定
                </Button>
              </DialogActions>
            </Dialog>
        </Card>

    )
}


PointTicketPolicy.propTypes = {
    className: PropTypes.string,
};

export default PointTicketPolicy;