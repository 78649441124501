import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Step, StepLabel, Stepper } from "@material-ui/core";
import { useSnackbar } from "notistack";
import Search from "./steps/Search"
import OTAList from "./steps/OTAList"
import Booking from "./steps/Booking";
import Order from "./steps/Order"
import Pay from "./steps/Pay";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  log: {
    background: '#f0f0f0',
    borderRadius: 5,
    marginTop: theme.spacing(10),
    width: '100%',
    minHeight: theme.spacing(10),
    maxHeight: theme.spacing(100),
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(2),
    overflow: 'scroll'
  }
}));

function getSteps() {
  return ['Search', 'OTA', 'Booking', 'Order', 'Pay'];
}

const Auto = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [log, setLog] = useState('');

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const [searchParam, setSearchParam] = useState({});
  const [searchProductList, setSearchProductList] = useState([]);
  const [otaProductList, setOtaProductList] = useState([]);
  const [bookingResult, setBookingResult] = useState({});
  const [orderResult,setOrderResult] = useState({});

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const parseSearchResult = (result) => {
    let productList = []
    Object.keys({ ...result.products }).forEach(i => {
      const flight = { ...{ ...result.flightInfos }[i] };
      ({ ...result.products }[i] || []).forEach(j => {
        if (flight.dep && flight.arr) {
          productList.push({
            'tripId': j.tripId,
            'dep': flight.dep,
            'arr': flight.arr,
            'dptTime': flight.dptTime,
            'arrTime': flight.arrTime,
            'carrier': flight.carrier,
            'productId': j.productId,
            'cabin': ({ ...j }.go || []).map(route => {
              return route.cabin + "/" + route.adultPrice.basePrice
            }).join(",") + ((j.back || []).length === 0 ? '' : "->" + ({ ...j }.back || []).map(route => {
              return route.cabin + "/" + route.adultPrice.basePrice
            }).join(","))
            , 'jsonContent': JSON.stringify(j)
          })
        }
      })
    });
    return productList;
  }

  return (
    <div className={classes.root}>
      <div>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div style={{ textAlign: 'center' }}>
          <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
            上一步
          </Button>
          <Button onClick={handleReset} className={classes.button}>
            重置
          </Button>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {activeStep === 0 ? (
            <Search
              onSearchResponse={(res, searchParam) => {
                setSearchParam(searchParam)
                setLog(res.message)
                if (res.ok) {
                  setSearchProductList(parseSearchResult(res.data))
                  handleNext()
                } else {
                  enqueueSnackbar("航班搜索报错，请关注返回日志", {
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                  });
                }
              }}
            />
          ) : null}
          {activeStep === 1 ? (
            <OTAList productList={searchProductList}
              searchParam={searchParam}
              onOTAResponse={(res) => {
                setLog(res.message)
                if (res.ok) {
                  setOtaProductList(parseSearchResult(res.data))
                  handleNext()
                } else {
                  enqueueSnackbar("ota搜索失败，请关注返回日志", {
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                  });
                }
              }} />
          ) : null}
          {activeStep === 2 ? (
            <Booking productList={otaProductList} onBookingResponse={(res) => {
              setLog(res.message)
              if (res.ok) {
                setBookingResult(res.data);
                handleNext()
              } else {
                enqueueSnackbar("booking失败，请关注返回日志", {
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                });
              }
            }} />
          ) : null}
          {activeStep === 3 ? (
            <Order bookingResult={bookingResult}
              searchParam={searchParam}
              onOrderResponse={
                (res) => {
                  setLog(res.message)
                  if (res.ok) {
                    setOrderResult(res.data);
                    handleNext()
                  } else {
                    enqueueSnackbar("order失败，请关注返回日志", {
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    });
                  }
                }
              } />

          ) : null}
          {activeStep === 4 ? (
              <Pay orderResponse={orderResult}
                   onPayResponse={
                       (res) => {
                           setLog(res.message)
                           let message = res.ok?"接口触发成功":"接口调用失败，请关注返回日志";
                           let variant = res.ok?"info":"error";
                           enqueueSnackbar(message, {
                               variant: variant,
                               anchorOrigin: {
                                   vertical: 'top',
                                   horizontal: 'center',
                               },
                           });
                       }
                   }
                    />

          ) : null}
        </Grid>
        <Grid item xs={6}>
          <pre className={classes.log}>{log}</pre>
        </Grid>
      </Grid>
    </div>
  );
};

export default Auto;
