import React, { useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import DashboardIcon from "@material-ui/icons/Dashboard";
import FlipIcon from "@material-ui/icons/Flip";
import SettingsIcon from '@material-ui/icons/Settings';
import ChildCareIcon from "@material-ui/icons/ChildCare";
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CodeIcon from '@material-ui/icons/Code';

import LocationCityIcon from '@material-ui/icons/LocationCity';
import { AppContext } from "adapter";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";

import { Profile, SidebarNav } from "./components";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)"
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { user } = useContext(AppContext);
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: "概览",
      href: "/dashboard",
      icon: <DashboardIcon />
    }, {
      title: "三字码",
      href: "/codeMap",
      icon: <LocationCityIcon />
    },
  ];

  if (user.agentDeveloper && !!user.agent) {
    pages.push({
      title: "代理商开发者",
      href: "/agentDeveloper",
      icon: <CodeIcon />
    });
  }
  if (!!user.agent || user.isAdmin) {
    // 有所属代理商的成员，或者管理员，才可以看到订单页面
    pages.push({
      title: "订单",
      href: "/order",
      icon: <ReceiptIcon />
    });
  }

  if (user.isAdmin) {
    pages.push({
      title: "OTA包",
      href: "/productBundle",
      icon: <DesktopMacIcon />
    });
    pages.push({
      title: "订单源",
      href: "/source",
      icon: <DesktopMacIcon />
    });
    pages.push({
      title: "代理商",
      href: "/agent",
      icon: <FlipIcon />
    });
    pages.push({
      title: "积分票配置",
      href: "/pointTicketPolicy",
      icon: <FlipIcon />
    });
    pages.push({
      title: "系统设置",
      href: "/config",
      icon: <SettingsIcon />
    });
    pages.push({
      title: "系统日志",
      href: "/sysLog",
      icon: <SettingsIcon />
    });
    pages.push({
      title: "账号信息",
      href: "/account",
      icon: <ChildCareIcon />
    });

  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
