import React, { useContext } from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import { AppContext } from "adapter";

import {
  Dashboard as DashboardView,
  Account as AccountView,
  SysLog as SysLogView,
  CodeMap as CodeMapView,
  ProductBundle as ProductBundleView,
  PointTicketPolicy as PointTicketPolicyView,
  OrderList as OrderListView,
  OrderDetail as OrderDetailView,
  OrderSourceList as OrderSourceListView,
  OrderSourceDetail as OrderSourceDetailView,
  AgentList as AgentListView,
  AgentDeveloper as AgentSettingView,
  Config as ConfigView,
  SignIn as SignInView,
  SignUp as SignUpView,
  NotFound as NotFoundView
} from "./views";

const PrivateRoute = ({ ...rest }) => {
  const { user } = useContext(AppContext);
  return !user.overdue ? (
    <RouteWithLayout {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: "/sign-in"
      }}
    />
  );
};

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />

      <PrivateRoute
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <PrivateRoute
        component={SysLogView}
        exact
        layout={MainLayout}
        path="/sysLog"
      />
      <PrivateRoute
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />

      <PrivateRoute
        component={CodeMapView}
        exact
        layout={MainLayout}
        path="/codeMap"
      />
      <PrivateRoute
        component={ProductBundleView}
        exact
        layout={MainLayout}
        path="/productBundle"
      />
      <PrivateRoute
        component={PointTicketPolicyView}
        exact
        layout={MainLayout}
        path="/pointTicketPolicy"
      />
      <PrivateRoute
        component={AgentListView}
        exact
        layout={MainLayout}
        path="/agent"
      />
      <PrivateRoute
        component={AgentSettingView}
        exact
        layout={MainLayout}
        path="/agentDeveloper"
      />
      <PrivateRoute
        component={OrderListView}
        exact
        layout={MainLayout}
        path="/order"
      />
      <PrivateRoute
        component={OrderDetailView}
        exact
        layout={MainLayout}
        path="/orderDetail/:id"
      />
      <PrivateRoute
        component={OrderSourceListView}
        exact
        layout={MainLayout}
        path="/source"
      />
      <PrivateRoute
        component={OrderSourceDetailView}
        exact
        layout={MainLayout}
        path="/sourceDetail/:id"
      />
      <PrivateRoute
        component={ConfigView}
        exact
        layout={MainLayout}
        path="/config"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
